import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getIsAdmin } from '@/const';
import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { productsFiltersActions } from '@/fsd/entities/ProductFilters';
import {
  getIdsStr,
  useGetRTKQProductsListQuery,
  useGetSettingsQuery,
} from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { fromJSON } from '@/fsd/shared/dto/product/fromJSON';
import { getOffersQtyByProduct } from '@/fsd/shared/lib/product/getOffersQtyByProduct';
import { TypeProduct } from '@/fsd/shared/types/scope/TypeProduct';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { Cards } from '../impl';

export function CardsByRTKQ() {
  const dispatch = useDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // const page = parseInt(query.get('page') || '1', 10);

  // const {
  //     data,
  //     isLoading,
  //     error,
  //     isFetching,
  //     // isSuccess, isError, refetch
  // } = useGetRTKQProductsListQuery(
  //     {
  //         filtersRTKQ,
  //         page,
  //         searchParamsString: query.toString(),
  //     } /* { skip: false } */
  // );
  const {
    data,
    isLoading,
    error,
    isFetching,
    // isSuccess, isError, refetch
  } = useGetRTKQProductsListQuery(query.toString());

  // завантаження налаштувань
  // завантаження налаштувань
  // завантаження налаштувань
  // завантаження налаштувань
  // завантаження налаштувань

  useGetSettingsQuery(null);

  // if (isLS) {
  //   console.log('CardsByRTKQ: Завантаження danceex settings...');
  // }

  // if (isErS) {
  //   console.log('CardsByRTKQ: Помилка завантаження danceex settings');
  // }

  // if (!data) {
  //   console.log('CardsByRTKQ: Дані для відображення ще не існують...');
  // }

  // завантаження налаштувань
  // завантаження налаштувань
  // завантаження налаштувань
  // завантаження налаштувань
  // завантаження налаштувань

  useEffect(() => {
    if (
      typeof data !== 'undefined' &&
      'updated_filters_by_filtered_products' in data
    ) {
      dispatch(
        productsFiltersActions.setFiltersFromRTKQ(
          data.updated_filters_by_filtered_products
        )
      );
    }
  }, [data]);

  if (isLoading || isFetching) {
    return (
      <div style={{ fontSize: '24px' }}>
        <CircularProgress />
        завантаження товарів...
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ fontSize: '24px', color: 'red' }}>
        Помилка завантаження товарів...
      </div>
    );
  }

  if (data) {
    const products = fromJSON(data.data);

    if (products.length < 1) {
      return (
        <div style={{ fontSize: '20px' }}>
          По Вашому запиту знайдено {products.length} товарів
        </div>
      );
    }

    return (
      <div style={{ padding: '12px 0', flexGrow: '1' }}>
        {getIsAdmin() && (
          <Statistics dataTotal={data.total} products={products} />
        )}

        <Cards products={products} />

        {/* <CustomPagination data={data} /> */}
        {(data as any)?.links.length > 2 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PaginationRouterIntegration data={data} />
          </div>
        )}
      </div>
    );
  }

  return <></>;
}

function Statistics({
  dataTotal,
  products,
}: {
  dataTotal: number;
  products: TypeProduct[];
}) {
  const existsProd = products.filter((p) => getOffersQtyByProduct(p) > 0);

  return (
    <>
      <Typography variant='h6'>{dataTotal} товарів</Typography>
      <Typography variant='h6'>
        {products.length} відображено на сторінці
      </Typography>
      <Typography variant='h6'>
        {existsProd.length} товарів в наявності
      </Typography>
    </>
  );
}

// function CustomPagination(props: any) {
//     const { data } = props;

//     return (
//         <div style={{ display: 'inline-flex', gap: '6px' }}>
//             {data?.links.map((l, key) => {
//                 return (
//                     <a href={l.url} key={key}>
//                         <span>{l.label}</span>
//                     </a>
//                 );
//             })}
//         </div>
//     );
// }

function PaginationRouterIntegration(props: any) {
  const { data } = props;

  const dispatch = useAppDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let page = parseInt(query.get('page') || '1', 10);

  if (page !== data?.current_page) {
    page = data?.current_page || undefined;

    // query.set('page', page.toString());
    // setSearchParams(query);
  }

  // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //     console.log('page value', value);
  // };

  return (
    <Pagination
      page={page}
      count={data?.links.length}
      variant='outlined'
      shape='rounded'
      // size='large'
      // onChange={handleChange}
      renderItem={(item) => {
        let to = '';
        if (item.page && data.links.length >= item.page) {
          to = '?' + data.links[item.page - 1].url;
        }

        return (
          <PaginationItem
            component={Link}
            to={to}
            {...item}
            onClick={() => dispatch(productsFiltersActions.setPage(item.page))}
            sx={{ width: '38px', height: '38px' }}
          />
        );
      }}
    />
  );
}

export function buildURLSearchParamsByFiltersRTKQ(filtersRTKQ: IFiltersRTKQ) {
  const urlParamValues: string[] = [];
  const arrEntries = Object.entries(filtersRTKQ);
  arrEntries.forEach((_) => {
    const name = _[0];
    const values = _[1].values;

    const ids = getIdsStr(values);
    if (ids.length > 0) {
      const item = name + '=' + ids;
      urlParamValues.push(item);
    }
  });

  const strUrlParamValues = urlParamValues.join('&');
  return strUrlParamValues;
}
