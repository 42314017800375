import { UpdateFiltersRTKQProps } from '@/fsd/pages/ProductsPage/lib/hooks/types/UpdateFiltersRTKQProps';
import { TitleBlock } from '@/fsd/shared/ui/TitleBlock';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';
import Typography from '@mui/material/Typography';

import cls from '../../index.module.scss';
import clsChooseColor from './ChooseSize.module.scss';

interface T {
  filterTypeName: string;
  title: string;
  values: AgeItemType[];
  onChange: ({ key, item }: UpdateFiltersRTKQProps) => void;
}

export const ChooseSize = (props: T) => {
  const { title, values, filterTypeName, onChange } = props;

  return (
    <div>
      <TitleBlock>{title}</TitleBlock>
      <div className={cls.WrapChooseColor}>
        {values.map((item, idx) => (
          // ColorItem(idx, item, filterTypeName)
          <ColorItem
            key={idx}
            idx={idx}
            item={item}
            filterTypeName={filterTypeName}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};

interface ColorItemType {
  idx: number;
  item: AgeItemType;
  filterTypeName: string;
  onChange: ({ key, item }: UpdateFiltersRTKQProps) => void;
}

function ColorItem({ idx, item, filterTypeName, onChange }: ColorItemType) {
  // const { onChange } = useProductFilters();

  const onClick = () => {
    const newItem: AgeItemType = {
      ...item,
      checked: !item.checked,
    };

    onChange({ item: newItem, key: filterTypeName });
  };

  return (
    <div
      key={idx}
      tabIndex={idx}
      data-id={item.id}
      // role='button'
      // aria-pressed='false'
      title={item.title}
      onClick={() => onClick()}
      className={item.checked ? clsChooseColor.selected : ''}
    >
      <Typography variant='subtitle1'>{item.title}</Typography>
    </div>
  );
}
