import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions } from '@/fsd/entities/cart';
import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';

export function Add({ cartItem }: { cartItem: CartItemType }) {
  const dispatch = useAppDispatch();

  const item = {
    product_id: cartItem.item.product_id,
    offer_id: cartItem.item.offer_id,
    color: cartItem.item.color,
  };

  const add = () => {
    dispatch(cartSliceActions.addProductById({ ...item }));
  };

  return (
    <IconButton aria-label='add' onClick={add}>
      <AddIcon />
    </IconButton>
  );
}
