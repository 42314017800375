import './Image.css';

import { Link } from 'react-router-dom';
import { TypeProductComponent } from 'src/fsd/shared/types';

import { getIsAdmin } from '@/const';
import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getSettingsRTKQ } from '@/fsd/entities/Settings/model/selectors/settingsSelectors';
import { RoutesNames } from '@/routes/RoutesName';

export const Image = ({ product }: TypeProductComponent) => {
  return (
    <div className='ProductCard-Image'>
      <Link tabIndex={0} to={RoutesNames.ProductPage + `${product.id}`}>
        <div className='wrap-imag-box'>
          <div className='img-box'>
            {getIsAdmin() && <SimpleBadge product={product} />}
            <img
              className='img-box--image'
              // 300x450
              src={
                product.thumbnail_url
                  ? product.thumbnail_url + '?w=530&h=798&fit=crop&v=1' // fit=crop робить чорну полоску для jpg
                  : '' // product.thumbnail_url + '?w=5&h=10&v=1' // '?w=5&h=10&fit=crop&v=1'
              }
              alt='Мініатюра товару'
              loading='lazy'
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

const SimpleBadge = ({ product }: TypeProductComponent) => {
  const settings = useAppSelector(getSettingsRTKQ);

  if (!settings) {
    return 'Stutus Loding...';
  }

  const visiStatus = settings.product.visible_status.data.find(
    ({ id }: any) => id === product.danceex_options.visible_status_id
  );

  let bgc = '#ff00a8';
  if (visiStatus.id === 1) {
    bgc = 'red';
  }
  if (visiStatus.id === 2) {
    bgc = 'green';
  }

  // if (visiStatus.id === 1) {
  //   return <></>;
  // }

  return (
    <span
      style={{
        position: 'absolute',
        padding: '4px 8px',
        top: '10px',
        right: '4px',
        maxWidth: '75%',
        backgroundColor: bgc,
        borderRadius: '4px',
        border: '1px solid wheat',
        color: 'wheat',
        fontWeight: 'bold',
      }}
    >
      {visiStatus.name}
    </span>
  );
};
