import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartRawItemType, CartSchema } from '../types/CartSchema';
import { getItemIndex } from '../utils/getItemIndex';

const initialState: CartSchema = {
  cart: [],
};

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    addProductById: (state, action: PayloadAction<CartRawItemType>) => {
      const index = getItemIndex(state.cart, action.payload);

      if (index > -1) {
        state.cart[index].qty++;
      } else {
        state.cart.push({
          qty: 1,
          item: { ...action.payload },
        });
      }
    },
    reduceProduct: (state, action: PayloadAction<CartRawItemType>) => {
      const index = getItemIndex(state.cart, action.payload);

      if (index > -1) {
        if (state.cart[index].qty > 0) {
          state.cart[index].qty--;
        }
      }
    },
    deleteProduct: (state, action: PayloadAction<CartRawItemType>) => {
      const index = getItemIndex(state.cart, action.payload);

      if (index > -1) {
        state.cart.splice(index, 1);
      }
    },
    emptyCart: (state) => {
      state.cart = [];
    },
  },
});

export const { actions: cartSliceActions, reducer: cartSliceReducer } =
  cartSlice;
