import { Link } from 'react-router-dom';
import { TypeProductComponent } from 'src/fsd/shared/types';

import { numCurrencyFormat } from '@/fsd/shared/utils';
import { RoutesNames } from '@/routes/RoutesName';

// import cls from './Description.module.css'; // працює, але не зручно з вкладеними стилями
import cls from './Description.module.scss';

export const Description = ({ product }: TypeProductComponent) => {
  // const qty = getOffersQtyByProduct(product);

  const dxColor = product.danceex_options.color;

  return (
    <>
      <div className={cls['ProductCard-Wrapper']}>
        <Link
          to={RoutesNames.ProductPage + `${product.id}`}
          className={cls['ProductCard-Title']}
        >
          {product.name}
        </Link>
        {dxColor && (
          <h4 className='FakeSwatches-Title'>
            Колір <span>{product.danceex_options.color}</span>
          </h4>
        )}
        <p aria-label='Ціна товару' className={cls.ProductPrice}>
          <span aria-label='Поточна ціна товару'>
            <data value={product.min_price}>
              {numCurrencyFormat({
                num: product.min_price,
                currency: product.currency_code,
              })}
            </data>
          </span>
        </p>
        {/* <p>Кількість в варіантах {product.quantity}</p> */}
        <span className='ProductDetails-Label'></span>
      </div>
    </>
  );
};
