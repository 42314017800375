// The file not typescripted. That is why disable ts check.
// @ts-nocheck
import 'react-image-gallery/styles/css/image-gallery.css';
import './CustomizedImageGallery2.css';

import React from 'react';

import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import cls from './CustomizedImageGallery.css?inline';

interface T {
  config: {
    youtube: {
      field_name: string;
    };
  };
  images: readonly ReactImageGalleryItem[];
}

export class CustomizedImageGallery extends React.Component<{
  objGallery: T;
  showThumbnails?: boolean;
  showBullets?: boolean;
  showIndex?: boolean;
}> {
  constructor(props: T) {
    super(props);
    this.state = {
      showIndex: props.showIndex === undefined ? true : props.showIndex,
      showBullets: props.showBullets === undefined ? true : props.showBullets,
      infinite: true,
      showThumbnails:
        props.showThumbnails === undefined ? true : props.showThumbnails,
      showFullscreenButton: true,
      // showFullscreenButton: props?.objGallery?.images[0]?.options
      //     ?.is_youtube
      //     ? false
      //     : true,
      showPlayButton: false,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      showVideo: true,
      // custom
      youtubFieldName: props.objGallery?.config?.youtube?.field_name,

      objGallery: props.objGallery,

      images: props?.objGallery?.images.map(($) => {
        const cloneEl = { ...$ };
        if (cloneEl?.options?.is_youtube) {
          // $.renderItem = this._renderYoutubeVideo.bind(this);
          cloneEl.renderItem = this._renderYoutubeVideo.bind(this);
          // originalClass: 'featured-slide',
          // thumbnailClass: 'featured-thumb',
          // description: 'Custom class for slides & thumbnails',
        }
        return cloneEl;
      }),
    };

    //// let cloneImages = [...this.props.objGallery.images];
    //// this.images = cloneImages.map(($) => {
    // this.images = props.objGallery.images.map(($) => {
    //     const cloneEl = { ...$ };
    //     if (cloneEl?.options?.is_youtube) {
    //         // $.renderItem = this._renderYoutubeVideo.bind(this);
    //         cloneEl.renderItem = this._renderYoutubeVideo.bind(this);
    //         // originalClass: 'featured-slide',
    //         // thumbnailClass: 'featured-thumb',
    //         // description: 'Custom class for slides & thumbnails',
    //     }
    //     return cloneEl;
    // });

    // if (this.images[0]?.options?.is_youtube) {
    //     this.state.showFullscreenButton = false;
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.objGallery !== prevProps.objGallery) {
      this.setState({
        objGallery: this.props.objGallery,
        youtubFieldName:
          this.props.objGallery?.config?.youtube?.field_name || '___nothing___',
        // images: this.props.objGallery.images,
        images: this.props?.objGallery?.images.map(($) => {
          const cloneEl = { ...$ };
          if (cloneEl?.options?.is_youtube) {
            // $.renderItem = this._renderYoutubeVideo.bind(this);
            cloneEl.renderItem = this._renderYoutubeVideo.bind(this);
            // originalClass: 'featured-slide',
            // thumbnailClass: 'featured-thumb',
            // description: 'Custom class for slides & thumbnails',
          }
          return cloneEl;
        }),
      });
    }
  }

  // _onImageClick(event) {
  //     return;
  //     console.debug(
  //         'clicked on image',
  //         event.target,
  //         'at index',
  //         this._imageGallery.getCurrentIndex()
  //     );
  // }

  _onSlide() {
    // _onSlide(index) {
    this._toggleShowFullscreenButton();
    // this._resetVideo();
    // console.debug('slid to index', index);
  }

  // _resetVideo() {
  //     const index = this._imageGallery.getCurrentIndex();
  //     const item = this.images[index];

  //     if (this.state.showPlayButton) {
  //         this.setState({ showGalleryPlayButton: true });
  //     }

  //     if (this.state.showFullscreenButton) {
  //         this.setState({ showGalleryFullscreenButton: true });
  //     }
  // }

  // _toggleShowVideo() {
  //     const { showVideo } = this.state;
  //     this.setState({
  //         showVideo: !showVideo,
  //     });

  //     if (!showVideo) {
  //         if (this.state.showPlayButton) {
  //             this.setState({ showGalleryPlayButton: false });
  //         }

  //         if (this.state.showFullscreenButton) {
  //             this.setState({ showGalleryFullscreenButton: false });
  //         }
  //     }
  // }

  // _renderVideo(item) {
  //     return (
  //         <div>
  //             {this.state.showVideo
  //                 ? this._renderYoutubeVideo(item)
  //                 : this._renderImage(item)}
  //         </div>
  //     );
  // }

  // private _renderImage(
  //     item: any
  // ): React.ReactNode | Iterable<React.ReactNode> {
  //     return (
  //         <>
  //             <button
  //                 className='play-button'
  //                 onClick={this._toggleShowVideo}
  //             />
  //             <img
  //                 alt='sample video cover'
  //                 className='image-gallery-image'
  //                 src={item.original}
  //             />
  //             {item.description && (
  //                 <span
  //                     className='image-gallery-description'
  //                     style={{ right: '0', left: 'initial' }}
  //                 >
  //                     {item.description}
  //                 </span>
  //             )}
  //         </>
  //     );
  // }

  private _toggleShowFullscreenButton() {
    const index = this._imageGallery.getCurrentIndex();
    // const item = this.images[index];
    const item = this.state.images[index];

    if (item?.options?.is_youtube) {
      if (this.state.showFullscreenButton === true) {
        this.setState({ showFullscreenButton: false });
      }
    } else {
      if (this.state.showFullscreenButton === false) {
        this.setState({
          showFullscreenButton: true,
        });
      }
    }
  }

  private _renderYoutubeVideo(
    item: any
  ): React.ReactNode | Iterable<React.ReactNode> {
    const st = { border: 'none' };
    const ar = item?.options?.css?.aspect_ratio;
    if (ar) {
      st.aspectRatio = ar;
    }

    return (
      <div className='video-wrapper'>
        {/* <button
                    className='close-video'
                    onClick={this._toggleShowVideo}
                /> */}
        <iframe
          className={cls.YoutubeVideo}
          title='sample video'
          src={item[this.state.youtubFieldName]}
          style={st}
          allowFullScreen
        />
      </div>
    );
  }

  render() {
    return (
      <section className='app'>
        <ImageGallery
          ref={(i) => (this._imageGallery = i)}
          items={this.state.images}
          // items={this.props.objGallery.images}
          // onClick={this._onImageClick.bind(this)}
          onSlide={this._onSlide.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton}
          showPlayButton={this.state.showPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
          additionalClass='app-image-gallery'
        />
      </section>
    );
  }
}
