import MultiColor from '@/assets/images/multicolor.png';
import { TitleBlock } from '@/fsd/shared/ui/TitleBlock';
import { AgeItemTypeColor } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';

import cls from '../../index.module.scss';
import clsChooseColor from './ChooseColor.module.scss';
import { ChooseColorProps } from './ChooseColorProps';

// import { ChooseColorProps } from './ChooseColorProps';

interface T {
  filterTypeName: string;
  title: string;
  values: AgeItemTypeColor[];
  onChangeColor: ({ key, item }: ChooseColorProps) => void;
}

export const ChooseColor = (props: T) => {
  const { title, values, filterTypeName, onChangeColor } = props;

  return (
    <div>
      <TitleBlock>{title}</TitleBlock>
      <div className={cls.WrapChooseColor}>
        {values.map((item, idx) => (
          <ColorItem
            key={idx}
            idx={idx}
            item={item}
            filterTypeName={filterTypeName}
            onChangeColor={onChangeColor}
          />
        ))}
      </div>
    </div>
  );
};

interface ColorItemType {
  idx: number;
  item: AgeItemTypeColor;
  filterTypeName: string;
  onChangeColor: ({ key, item }: ChooseColorProps) => void;
}

function ColorItem({
  idx,
  item,
  filterTypeName,
  onChangeColor,
}: ColorItemType) {
  // const { onChangeColor } = useProductFilters();

  const onClick = () => {
    const newItem: AgeItemTypeColor = {
      ...item,
      checked: !item.checked,
    };

    onChangeColor({ item: newItem, key: filterTypeName });
  };

  if (item.bgColor === 'MultiColor') {
    return (
      <div
        key={-1}
        title={item.title}
        onClick={onClick}
        className={item.checked ? clsChooseColor.selected : ''}
        style={{ padding: '0' }}
      >
        <picture>
          <source srcSet={MultiColor} />
          <img
            src={MultiColor}
            alt='Color diversity'
            style={{ display: 'block' }}
            // width='32px'
            // height='32px'
          />
        </picture>
      </div>
    );
  }

  return (
    <div
      key={idx}
      tabIndex={idx}
      data-id={item.id}
      role='button'
      aria-pressed={item.checked ? true : false}
      title={item.title}
      style={{ backgroundColor: item.bgColor }}
      onClick={onClick}
      className={item.checked ? clsChooseColor.selected : ''}
    ></div>
  );
}
