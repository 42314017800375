import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { RenderItemMenu } from './RenderItemMenu/RenderItemMenu';

export function RenderMenu({
  requestedFiltersRTKQ,
}: {
  requestedFiltersRTKQ: IFiltersRTKQ;
}) {
  const data = requestedFiltersRTKQ.main_menu.values;

  return (
    <NavigationMenu.Root className='NavigationMenuRoot'>
      <NavigationMenu.List className='NavigationMenuList'>
        {data.map((item, idx) => (
          <RenderItemMenu
            key={idx}
            item={item}
            requestedFiltersRTKQ={requestedFiltersRTKQ}
          />
        ))}
        <NavigationMenu.Indicator className='NavigationMenuIndicator'>
          <div className='Arrow' />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className='ViewportPosition'>
        <NavigationMenu.Viewport className='NavigationMenuViewport' />
      </div>
    </NavigationMenu.Root>
  );
}
