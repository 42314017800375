// import { REHYDRATE } from 'redux-persist';

import { getIsAdmin } from '@/const';
import { cartProductsSliceActions } from '@/fsd/entities/cartProducts';
import { mainMenuActions } from '@/fsd/entities/MainMenu/model/slices/mainMenuSlice';
import { selectedOfferActions } from '@/fsd/entities/SelectedOffer';
import { selectedProductActions } from '@/fsd/entities/SelectedProduct';
import { selectedProductGroupActions } from '@/fsd/entities/SelectedProductGroup';
import { settingsActions } from '@/fsd/entities/Settings/model/slices/settingsSlice';
import { TypeProductsResponse } from '@/fsd/shared/types/scope/TypeProductsResponse';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';
// import type { Action } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { productsFiltersActions } from '../../../';
import { IFiltersRTKQ } from '../../types/filtersRTKQType';

// type RootState = any; // normally inferred from state

// function isHydrateAction(action: Action): action is Action<typeof REHYDRATE> & {
//     key: string;
//     payload: RootState;
//     err: unknown;
// } {
//     return action.type === REHYDRATE;
// }

const baseUrl = import.meta.env.VITE_API_BASE_URL;
// Старий бек
const API_ALL_PRODUCT_BY_FILTERS = '/get_products_by_filters.php'; // Пагіновані продукти, можуть бути відфільтровані
const API_PRODUCT_BY_IDs = '/get_products_by_ids.php'; // Продукти за списком ID-шників

// Новий бек
// const API_ALL_PRODUCT_BY_FILTERS = '/products_copies';
// const API_PRODUCT_BY_IDs = '/products_copies'; // Продукт ID

export const getIdsStr = (values: AgeItemType[]) => {
  if (values.length === 0) return '';

  const result = values
    .filter((_) => _.checked)
    .map((_) => _.id)
    .join(',');

  return result;
};

const getAdminHeaders = () => {
  let headers = undefined;
  if (getIsAdmin()) {
    headers = {
      something: getIsAdmin() ? 'xxx' : '',
      'Content-Type': 'application/json; charset=UTF-8',
    };
  }

  return headers;
};

export const RTKQProductsListApi = createApi({
  reducerPath: 'api/fetchProductsList',
  baseQuery: fetchBaseQuery({ baseUrl }),
  // to prevent circular type issues, the return type needs to be annotated as any
  // extractRehydrationInfo(action, { reducerPath }): any {
  //     if (isHydrateAction(action)) {
  //         // when persisting the api reducer
  //         if (action.key === 'key used with redux-persist') {
  //             return action.payload;
  //         }

  //         // When persisting the root reducer
  //         return action.payload[RTKQProductsListApi.reducerPath];
  //     }
  // },
  endpoints: (builder) => ({
    getRTKQProductsList: builder.query<
      TypeProductsResponse, // return data type
      // IFiltersRTKQ_Page // params
      string | undefined
    >({
      // keepUnusedDataFor: 0,
      // query: ({ filtersRTKQ, page, searchParamsString }) => {
      query: (searchParamsString) => {
        // filtersRTKQ;
        // page;

        // const query = new URLSearchParams(location.search);
        // console.log(query.toString());

        // let [searchParams0, setSearchParams0] = useSearchParams();
        // console.log(searchParams0);

        // let searchParamsByFunc =
        //     buildKeyValueQueryParamsByFiltersRTKQ(filtersRTKQ);
        // // keyValue = { ...keyValue, page };
        // if (searchParams?.size) {
        //     searchParamsByFunc.set('page', page.toString());
        // }

        // const suffix = searchParamsByFunc.toString();

        const headers = getAdminHeaders();

        return {
          url: API_ALL_PRODUCT_BY_FILTERS + '?' + searchParamsString,
          headers,
        };
        // return {
        //     url: API_ALL_PRODUCT_BY_FILTERS,
        //     params: { ...searchParams },
        // };
      },

      // async onQueryStarted(_id, { getState, queryFulfilled, dispatch,  }) {
      //     try {
      //         console.log('---------- getState ----------');
      //         console.log(getState());
      //         console.log(getState()['api/fetchProductsList'].queries);

      //         const f = {
      //             ...getState()['api/fetchProductsList'].queries,
      //         };

      //         const result = await queryFulfilled;
      //         const data = result.data;

      //         dispatch(productsFiltersActions.setFiltersFromRTKQ(data));
      //     } catch (err) {
      //         console.log(err);
      //     }
      // },
    }),

    // getRTKQProductsList: builder.query<
    //     TypeProductsResponse, // return data type
    //     // IFiltersRTKQ // params
    //     IFiltersRTKQ_Page // params
    // >({
    //     keepUnusedDataFor: 0,
    //     query: ({ filtersRTKQ, page, searchParams }) => {
    //         // const query = new URLSearchParams(location.search);
    //         // console.log(query.toString());

    //         // let [searchParams0, setSearchParams0] = useSearchParams();
    //         // console.log(searchParams0);

    //         const url = API_ALL_PRODUCT_BY_FILTERS;

    //         let searchParamsByFunc =
    //             buildKeyValueQueryParamsByFiltersRTKQ(filtersRTKQ);
    //         // keyValue = { ...keyValue, page };
    //         if (page) {
    //             searchParamsByFunc.set('page', page.toString());
    //         }

    //         const suffix = searchParamsByFunc.toString();

    //         return url + suffix;
    //     },
    // }),

    // getRTKQProductsList: builder.query<
    //     TypeProductsResponse, // return data type
    //     // IFiltersRTKQ // params
    //     IFiltersRTKQ_Page // params
    // >({
    //     keepUnusedDataFor: 0,
    //     query: ({ filtersRTKQ, page }) => {
    //         const url = API_ALL_PRODUCT_BY_FILTERS;
    //         const suffix = buildURLSuffix({ filtersRTKQ, page });

    //         // let suffix = '';
    //         // const strUrlParamValues =
    //         //     buildQueryParamsByFiltersRTKQ(filtersRTKQ);

    //         // if (strUrlParamValues.length > 0) {
    //         //     suffix += `${strUrlParamValues}`;
    //         // }

    //         // if (page) {
    //         //     if (suffix.length > 0) {
    //         //         suffix = suffix + '&';
    //         //     }
    //         //     suffix = suffix + 'page=' + page;
    //         // }

    //         // if (suffix.length > 0) {
    //         //     suffix = '?' + suffix;
    //         // }

    //         return url + suffix;
    //     },
    // }),
    getProductsByIds: builder.query<TypeProductsResponse, number[]>({
      keepUnusedDataFor: 0,
      query: (ids) => {
        // return API_PRODUCT_BY_IDs + `/${ids.join(',')}`;

        const headers = getAdminHeaders();

        return {
          url: API_PRODUCT_BY_IDs + `?ids=${ids.join(',')}`,
          headers,
        };

        // return {
        //     url: API_PRODUCT_BY_IDs,
        //     params: { ids: ids.join(',') },
        // };
      },
      async onQueryStarted(_id, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          const resp = result.data;
          // dispatch(
          //     selectedProductGroupActions.setSelectedProductGroup(
          //         resp.data
          //     )
          // );
          // const product = resp.data[0];
          // dispatch(
          //     selectedProductActions.setSelectedProduct(product)
          // );
          // let offer = undefined;
          // if (product.danceex_options.offers.length === 1) {
          //     offer = product.danceex_options.offers[0];
          // }
          // dispatch(selectedOfferActions.setSelectedOffer(offer));

          dispatch(
            selectedProductGroupActions.setSelectedProductGroup(resp.data)
          );

          const product = resp.data[0];
          dispatch(selectedProductActions.setSelectedProduct(product));

          let offer = undefined;
          if (product.danceex_options.offers.length === 1) {
            offer = product.danceex_options.offers[0];
          }
          dispatch(selectedOfferActions.setSelectedOffer(offer));

          dispatch(
            selectedProductGroupActions.setSelectedProductGroupAdmin(resp.data)
          );

          console.log('useGetProductsByIdsQuery', product.id);
          dispatch(selectedProductActions.setSelectedProductAdmin(product));

          dispatch(selectedOfferActions.setSelectedOfferAdmin(offer));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getProductsByIdsCart: builder.query<TypeProductsResponse, number[]>({
      keepUnusedDataFor: 0,
      query: (ids) => {
        return API_PRODUCT_BY_IDs + `?ids=${ids.join(',')}`;
      },
      async onQueryStarted(_id, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          const resp = result.data;
          const products = resp.data;

          dispatch(cartProductsSliceActions.setProducts(products));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getFilters: builder.query<IFiltersRTKQ, null>({
      //   keepUnusedDataFor: 0,
      query: () => {
        return { url: '/get_filters.php' };
      },
      transformResponse: (response: IFiltersRTKQ) => {
        const DTO_data = Object.fromEntries(
          Object.entries(response as IFiltersRTKQ).map((item) => {
            const newValues = item[1].values.map((v) => ({
              ...v,
              checked: false,
            }));

            item[1].values = [...newValues];

            return item;
          })
        );

        return DTO_data;
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const data = result.data;

          dispatch(productsFiltersActions.setFiltersFromRTKQ(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getMainMenu: builder.query<IFiltersRTKQ, null>({
      query: () => {
        return { url: '/get_main_menu.php' };
      },
      transformResponse: (response: IFiltersRTKQ) => {
        const DTO_data = Object.fromEntries(
          Object.entries(response as IFiltersRTKQ).map((item) => {
            if ('values' in item[1] && Array.isArray(item[1].values)) {
              const newValues = item[1].values.map((v) => ({
                ...v,
                checked: false,
              }));

              item[1].values = [...newValues];
            }

            return item;
          })
        );

        return DTO_data;
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const data = result.data;

          dispatch(mainMenuActions.setMainMenuRTKQ(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getSettings: builder.query<any, null>({
      query: () => {
        return { url: '/get_danceex_settings.php' };
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const data = result.data;

          dispatch(settingsActions.setSettingsRTKQ(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});

export const {
  useGetRTKQProductsListQuery,
  useGetProductsByIdsQuery,
  useGetProductsByIdsCartQuery,
  useGetFiltersQuery,
  useGetMainMenuQuery,
  useGetSettingsQuery,
} = RTKQProductsListApi;
