export const MAX_WIDTH_TABLET = '(max-width: 900px)';
export const __IS_DEV__ = import.meta.env.VITE_IS_DEV;

// const contentEditable = sessionStorage.getItem('contentEditableTest0') ?? false;
// export const __IS_ADMIN_MODE__ = Boolean(
//   import.meta.env.VITE_IS_ADMIN_MODE === 'true' && contentEditable
//     ? true
//     : false
// );

export const getIsAdmin = () => {
  const contentEditable =
    sessionStorage.getItem('contentEditableTest0') ?? false;
  return Boolean(
    import.meta.env.VITE_IS_ADMIN_MODE === 'true' && contentEditable
      ? true
      : false
  );
};

// Для яких властивостей створювати кнопку вибору розміру 
export const uniq_props = [
  'Розмір',
  // "Колір",
  'Зріст',
  'Висота підборів',
  'Розмір взуття',
  'Каблук',
  'Матеріал',
];
