import { useParams } from 'react-router-dom';

import { useGetProductsByIdsQuery } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';

import { ProductNotFound } from '../impl/widgets';
import { RenderPageAsync } from './RenderPage/RenderPage.async';

export const ProductPage = () => {
  // const product = useLoaderData() as TypeProduct | null;
  const { id } = useParams();

  if (id == undefined) return <ProductNotFound />;

  return <Processing id={id} />;
};

function Processing(props: { id: string }) {
  const { id } = props;
  const { currentData, isLoading, error } = useGetProductsByIdsQuery([+id]);

  if (isLoading) {
    return (
      <div className='ContentWrapper'>
        <div style={{ fontSize: '24px' }}>Завантаження товару № {id}...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='ContentWrapper'>
        <div style={{ fontSize: '24px', color: 'red' }}>
          Помилка завантаження товару № {id}
        </div>
      </div>
    );
  }

  if (!currentData || currentData.data.length === 0) {
    return <ProductNotFound />;
  }

  return <RenderPageAsync />;
}
