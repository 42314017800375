import { CartItemType, CartRawItemType } from '../types/CartSchema';

export function getItemIndex(cart: CartItemType[], cartItem: CartRawItemType) {
  const { product_id, offer_id, color } = cartItem;

  return cart.findIndex(
    (i) =>
      i.item.product_id === product_id &&
      i.item.offer_id === offer_id &&
      i.item.color === color
  );
}
